import anime from 'animejs';
export var nivos = function (sliderSelector, interval, catchSelector) {
    // 初期設定
    var slider = document.querySelector(sliderSelector);
    var sliderChildren = slider.children;
    var activeIndex = 1;
    var splitX = 8;
    var splitY = 5;
    var pattern = {
        0: {
            scaleY: [1, 0],
            easing: 'easeOutExpo',
            endDelay: interval,
            delay: anime.stagger(50, {
                grid: [splitX, splitY],
                from: 'center',
                easing: 'linear'
            })
        },
        1: {
            scale: [1, 0],
            rotate: [0, 360],
            easing: 'easeOutExpo',
            endDelay: interval,
            delay: anime.stagger(100, {
                grid: [splitX, splitY],
                from: 'center',
                easing: 'linear'
            })
        },
        2: {
            scaleY: [1, 0],
            easing: 'easeOutExpo',
            endDelay: interval,
            delay: anime.stagger(50, {
                grid: [splitX, splitY],
                from: 'first',
                axis: 'y',
                easing: 'linear'
            })
        },
        3: {
            scaleX: [1, 0],
            easing: 'easeOutExpo',
            endDelay: interval,
            delay: anime.stagger(50, {
                grid: [splitX, splitY],
                from: 'first',
                axis: 'x',
                easing: 'linear'
            })
        }
    };
    var catchIndex;
    var catchWapper = null;
    var catches;
    var catchAnime;
    // 関数宣言
    var createDiv = function () {
        var children = Array.from(sliderChildren);
        children.forEach(function (element, index) {
            var el = document.createElement('div');
            anime.set(element, {
                display: 'flex',
                flexWrap: 'wrap',
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: index * -1 + children.length
            });
            for (var fi = 0; fi < splitX * splitY; fi++) {
                element.appendChild(el.cloneNode(true));
            }
        });
    };
    var setBackground = function (sliderW, sliderH) {
        var children = Array.from(sliderChildren);
        children.forEach(function (element) {
            var img = element.querySelector('img');
            var divs = element.querySelectorAll('div');
            var imgW = img.naturalWidth;
            var imgH = img.naturalHeight;
            var imgSrc = img.getAttribute('src');
            var bgs;
            var fixX = 0;
            var fixY = 0;
            if (sliderW / sliderH < imgW / imgH) {
                bgs = "auto " + sliderH + "px";
                fixX = ((sliderH / imgH) * imgW - sliderW) / 2;
            }
            else {
                bgs = sliderW + "px auto";
                fixY = ((sliderW / imgW) * imgH - sliderH) / 2;
            }
            for (var i = 0; i < divs.length; i++) {
                var bgpX = (i % splitX) / splitX;
                var bgpY = Math.floor(i / splitX) / splitY;
                anime.set(divs[i], {
                    width: 100 / splitX + "%",
                    height: 100 / splitY + "%",
                    backgroundImage: "url('" + imgSrc + "')",
                    backgroundPosition: -1 * bgpX * sliderW - fixX + "px " + (-1 * bgpY * sliderH - fixY) + "px",
                    backgroundSize: bgs
                });
            }
        });
    };
    var init = function () {
        anime.set(slider, {
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            width: '100%'
        });
        slider.querySelectorAll('img').forEach(function (el) {
            el.style.display = 'none';
        });
        createDiv();
        setBackground(slider.clientWidth, slider.clientHeight);
        activeIndex++;
    };
    var initCatch = function () {
        catchWapper = document.querySelector(catchSelector);
        catches = catchWapper.querySelectorAll(':scope > *');
        catchIndex = 1;
        catchAnime = {
            opacity: [0, 1],
            translateX: ['10%', 0],
            duration: 2000,
            easing: 'easeOutExpo'
        };
        anime.set(catchWapper, {
            height: anime.get(catches[0], 'height')
        });
        catches.forEach(function (catche) {
            anime.set(catche, {
                left: 0,
                opacity: 0,
                position: 'absolute',
                top: 0
            });
        });
        catchAnime.targets = catches[0];
        anime(catchAnime);
        catchIndex++;
    };
    var catchTransition = function () {
        if (catchIndex > catches.length) {
            catchIndex = 1;
        }
        anime.set(catches, {
            opacity: 0
        });
        catchAnime.targets = catches[catchIndex - 1];
        anime(catchAnime);
        catchIndex++;
    };
    var animation = {
        clearId: null,
        start: function () {
            this.clearId = window.setInterval(this.update, interval);
        },
        stop: function () {
            if (this.clearId) {
                clearInterval(this.clearId);
            }
        },
        update: function () {
            var childlen = Array.from(sliderChildren);
            var hiddenTarget;
            if (activeIndex > childlen.length) {
                activeIndex = 1;
                hiddenTarget = slider.querySelector(":scope > div:nth-of-type(" + childlen.length + ")");
            }
            else {
                hiddenTarget = slider.querySelector(":scope > div:nth-of-type(" + (activeIndex - 1) + ")");
            }
            var innerDivs = hiddenTarget.querySelectorAll(':scope > div');
            var patternNum = hiddenTarget.getAttribute('data-nivos');
            // if (patternNum === null) {
            //   patternNum = '0';
            // }
            var options = Object.assign(pattern[patternNum], {
                targets: innerDivs,
                begin: function () {
                    if (catchSelector) {
                        catchTransition();
                    }
                },
                complete: function () {
                    var children = Array.from(sliderChildren);
                    for (var i = 0; i < innerDivs.length; i++) {
                        innerDivs[i].style.transform = '';
                        innerDivs[i].style.opacity = '1';
                    }
                    var z_index = children.map(function (el) { return el.style.zIndex; });
                    z_index.unshift(z_index.pop());
                    children.forEach(function (el, index) {
                        el.style.zIndex = z_index[index];
                    });
                }
            });
            anime(options);
            activeIndex++;
        }
    };
    // 処理開始
    window.addEventListener('load', function () {
        init();
        if (catchSelector) {
            initCatch();
        }
        animation.start();
    });
    window.addEventListener('resize', function () {
        setBackground(slider.clientWidth, slider.clientHeight);
    });
    document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
            animation.stop();
        }
        else {
            animation.start();
        }
    });
};
