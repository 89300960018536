/*
 * ******************************************************************
 * パッケージ
 * ******************************************************************
 */
// const porifill = async () => {
//   await import('intersection-observer');
//   const smoothscroll = await import('smoothscroll-polyfill');
//   smoothscroll.polyfill();
// };
// document.addEventListener('DOMContentLoaded', () => {
//   porifill();
// });
/* ポリフィル */
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
/* ライブラリ */
export { Collapse } from 'bootstrap';
export { default as AOS } from 'aos';
export { default as Swiper } from 'swiper/bundle';
/*
 * ******************************************************************
 * 自作モジュール - 共通
 * ******************************************************************
 */
export { areaOvserve } from '~/modules/areaOvserve';
export { getCms } from '~/modules/getCms';
export { humbergerMenu } from '~/modules/humbergerMenu';
export { modalWindow } from '~/modules/modalWindow';
export { nivos } from '~/modules/nivos';
export { smoothScroll } from '~/modules/smoothScroll';
export { toggleClassByScrolling } from '~/modules/toggleClassByScrolling';
/*
 * ******************************************************************
 * SASS
 * ******************************************************************
 */
import '~/scss/aos.scss';
import '~/scss/bootstrap.scss';
import '~/scss/swiper.scss';
